/*index.css*/
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* スピナーのアニメーション */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  /* ライトグレー */
  border-top: 4px solid #3498db;
  /* ブルー */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spinner 1s linear infinite;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

body {
  /* iOS Safariで表示領域いっぱいを使う */
  height: -webkit-fill-available;
}

/* ラッパーコンテナ（.Appやメインレイアウトコンテナなど）にも100%高さを指定 */
#root,
.App {
  height: 100%;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* iOS Safariで安全領域を考慮 */
  min-height: -webkit-fill-available;
}

#root {
  height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';